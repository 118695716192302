<div class="box" prizmFocusTrap prizmTheme>
  <div
    class="header prizm-font-title-h4"
    *ngIf="context.headerTemplate ?? context.header"
    [ngSwitch]="!!context.headerTemplate"
  >
    <ng-container *ngSwitchCase="true">
      <ng-container *polymorphOutlet="context.headerTemplate as headerTemplate; context: context">
        {{ headerTemplate }}
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <prizm-scrollbar
        *polymorphOutlet="context.header as data; context: context"
        [visibility]="context.scrollbarVisibility ?? 'hidden'"
      >
        <div class="title">
          {{ data }}
        </div>
      </prizm-scrollbar>
      <button
        *ngIf="context.closeable"
        [size]="'m'"
        [icon]="'cancel-close'"
        (click)="closeSidebar()"
        appearanceType="ghost"
        appearance="secondary"
        prizmIconButton
      ></button>
    </ng-container>
  </div>

  <div class="content prizm-font-main-body-14">
    <ng-container *polymorphOutlet="context.outerContent as outerContent">
      <ng-container *ngIf="outerContent; else innerTemplate">
        {{ outerContent }}
      </ng-container>
      <ng-template #innerTemplate>
        <prizm-scrollbar class="scrollbar full-height" [visibility]="context.scrollbarVisibility ?? 'auto'">
          <div class="full-height" *polymorphOutlet="context.content as text; context: context">
            {{ text }}
          </div>
        </prizm-scrollbar>
      </ng-template>
    </ng-container>
  </div>

  <div class="footer prizm-font-btn-links-14" *ngIf="!context.hideFooter">
    <ng-container *ngIf="context.footer; else notFooter">
      <div *polymorphOutlet="context.footer as footer; context: context">
        {{ footer }}
      </div>
    </ng-container>
    <ng-template #notFooter>
      <ng-container>
        <div class="horizontal">
          <div class="left">
            <ng-container
              *ngIf="context.supportButton"
              [ngTemplateOutlet]="buttonTemplate"
              [ngTemplateOutletContext]="{ button: context.supportButton }"
            >
            </ng-container>
          </div>
          <div class="right">
            <ng-container
              *ngIf="context.cancelButton"
              [ngTemplateOutlet]="buttonTemplate"
              [ngTemplateOutletContext]="{ button: context.cancelButton }"
            >
            </ng-container>

            <ng-container
              *ngIf="context.confirmButton"
              [ngTemplateOutlet]="buttonTemplate"
              [ngTemplateOutletContext]="{ button: context.confirmButton }"
            >
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-template #buttonTemplate let-button="button">
        <button
          [style]="button.style"
          [appearance]="button.appearance"
          [appearanceType]="button.appearanceType"
          [disabled]="$any(button.disabled ?? false | prizmToObservable | async)"
          [showLoader]="$any(button.showLoader ?? false | prizmToObservable | async)"
          [size]="button.size"
          [icon]="button.icon"
          (click)="button.action(context)"
          prizmButton
        >
          {{ button.text }}
        </button>
      </ng-template>
    </ng-template>
  </div>
</div>
