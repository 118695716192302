<prizm-dropdown-host
  class="z-wrapper"
  [canOpen]="interactive && !!filtered.length"
  [content]="dropdownContent"
  [prizmDropdownHostWidth]="'100%'"
  [isOpen]="interactive && open && !!filtered.length"
  [closeByEsc]="true"
  [prizmDropdownHost]="layoutComponent?.el?.nativeElement"
  (isOpenChange)="onOpen($event)"
>
  <input
    class="input-search"
    #focusableElementRef
    [mask]="textMaskOptions"
    [showMaskTyped]="focusableElementRef.focused"
    [dropSpecialCharacters]="false"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="computedValue"
    (click)="safeOpenModal()"
    (ngModelChange)="onValueChange($event || '')"
    (keydown.arrowUp)="onArrowUp($event)"
    (keydown.arrowDown)="onArrowDown($event)"
    prizmInput
  />
</prizm-dropdown-host>

<ng-template #dropdownContent>
  <prizm-data-list class="block" [style.--prizm-data-list-border]="0" (prizmAfterViewInit)="markAsTouched()">
    <ng-container *ngIf="items?.length">
      <prizm-listing-item
        *ngFor="let item of filtered; let idx = index"
        [selected]="value && item.isSameTime(value)"
        (click)="onMenuClick(item)"
        >{{ item }}
      </prizm-listing-item>
    </ng-container>
  </prizm-data-list>
</ng-template>

<ng-container *prizmInputLayoutRight>
  <button
    [interactive]="true"
    [disabled]="disabled"
    (click)="onOpen(!open)"
    prizmInputIconButton="date-clock"
  ></button>
  <ng-container *ngFor="let button of rightButtons$ | async" [ngTemplateOutlet]="button.template">
  </ng-container>
</ng-container>
