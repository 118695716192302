<div
  class="accordion"
  [class.accordion_expanded]="isExpanded"
  [class.accordion_disabled]="disabled"
  [class.accordion_focused]="isAccordionFocused"
>
  <div
    class="accordion__header"
    [tabindex]="disabled ? -1 : 0"
    (click)="toggle()"
    (keydown.enter)="toggle()"
    (keydown.space)="toggle()"
    (focus)="isAccordionFocused = true"
    (focusout)="isAccordionFocused = false"
  >
    <ng-container *ngIf="icon">
      <ng-container *polymorphOutlet="icon as content; context: data">
        <!-- TODO add type string-->
        <prizm-icon class="header-icon" [iconClass]="$any(content)" size="16"></prizm-icon>
      </ng-container>
    </ng-container>
    <div class="title" #elem *ngIf="title" [prizmHint]="title" [prizmHintCanShow]="prizmIsTextOverflow(elem)">
      <ng-container *polymorphOutlet="title as content; context: data">
        {{ content }}
      </ng-container>
    </div>
    <div
      class="accordion__tools"
      *ngIf="accordionTools"
      (click)="stopProp($event)"
      (keydown.enter)="stopProp($event)"
      (keydown.space)="stopProp($event)"
    >
      <ng-container *ngTemplateOutlet="accordionTools"></ng-container>
    </div>
    <div class="btn-wrapper">
      <button
        class="accordion__button"
        [class.accordion_expanded__button]="isExpanded"
        [icon]="'chevrons-double-down'"
        [disabled]="disabled"
        prizmIconButton
        appearanceType="ghost"
        appearance="secondary"
        size="m"
        tabindex="-1"
      ></button>
    </div>
  </div>
  <div class="accordion__content" *ngIf="accordionContent" [@expand]="isExpanded ? 'expanded' : 'init'">
    <ng-container *ngTemplateOutlet="accordionContent"></ng-container>
  </div>
</div>
