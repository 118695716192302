<prizm-dropdown-host
  class="z-hosted"
  [canOpen]="canOpen"
  [content]="dropdown"
  [isOpen]="isOpen && canOpen"
  [closeByEsc]="true"
  [prizmDropdownHost]="layoutComponent?.el?.nativeElement"
  [prizmDropdownHostWidth]="'100%'"
  (isOpenChange)="onOpenChange($event)"
>
  <input
    class="input-search"
    #focusableElementRef
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="value"
    [disabled]="disabled"
    [placeholder]="placeholder"
    (click)="safeOpenModal()"
    (ngModelChange)="valueChange($event)"
    prizmInput
  />
</prizm-dropdown-host>

<ng-template #dropdown>
  <prizm-data-list class="block">
    <ng-container *ngTemplateOutlet="menuItemsTemplate; context: { items: timeItems }"></ng-container>
    <div class="relative-menu-item-divider"></div>
    <ng-container *ngTemplateOutlet="menuItemsTemplate; context: { items: directionItems }"></ng-container>
    <div class="relative-menu-item-divider"></div>
    <ng-container *ngTemplateOutlet="menuItemsTemplate; context: { items: periodItems }"></ng-container>
  </prizm-data-list>

  <ng-template #menuItemsTemplate let-items="items">
    <prizm-listing-item
      *ngFor="let item of items"
      [selected]="item.active"
      (click)="onMenuItemClick($event, item)"
      tabindex="0"
    >
      <ng-container leftBox>
        <i class="prizm-icon relative-menu-icon {{ item.icon }}"></i>
      </ng-container>
      {{ item.label }}
    </prizm-listing-item>
  </ng-template>
</ng-template>

<ng-container *prizmInputLayoutRight>
  <button
    [interactive]="true"
    [disabled]="disabled"
    (click)="focusableElementRef.elementRef.nativeElement.click()"
    prizmInputIconButton="date-update"
  ></button>
  <ng-container *ngFor="let button of rightButtons$ | async" [ngTemplateOutlet]="button.template">
  </ng-container>
</ng-container>

<ng-template [enable]="!!ngControl.errors?.requiredPattern" prizmInputStatusText status="danger">
  {{ dictionary$ | async | prizmPluck : 'wrongFormat' }}
</ng-template>
